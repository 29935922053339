import Slideshow from './Slideshow_living';


export default function Living() {
	return <>
		<div className="grid grid-cols-10 mt-3">
			<div>
				<a href="/Appartement" className="font-roboto text-xl ml-1 sm:ml-5 py-1 px-3 font-bold border-solid border-2 border-black rounded-lg hover:bg-gray-200">Back</a>
			</div>
			<div className="mb-5 col-span-8 text-center font-lobster text-3xl sm:text-4xl">
				Livingroom
				</div>
			<div className="col-span-10 mx-5">
				<Slideshow />
			</div>
			<div className="font-roboto col-span-10 text-l mx-10 my-3">
				<p>
				A modern light-filled open plan living area with an adjoining large terrace of 45m².
The whole gives an unobstructed view of the natural valley and a slight sea view, as La Maravilla is located in the highest building of Agora.
The living room offers a cosy sitting area with matching tables and cupboards.
You can enjoy the 50" flat screen Smart TV with built-in Chromecast and sound bar system.
Hot and cold air conditioning is available everywhere.
				</p>
			</div>
		</div>
	</>
}