import React, { Component } from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Agora1 from '../afbeeldingen/Appartement/Buiten 1.JPG'
import Agora2 from '../afbeeldingen/Appartement/Buiten 2.JPG'
import Agora3 from '../afbeeldingen/Appartement/Buiten 3.JPG'
import Agora4 from '../afbeeldingen/Appartement/Buiten 4.JPG'
import Agora5 from '../afbeeldingen/Appartement/Buiten 5.jpg'
import Agora6 from '../afbeeldingen/Appartement/Buiten 6.jpg'
import Agora7 from '../afbeeldingen/Appartement/Buiten 7.jpg'
import Agora8 from '../afbeeldingen/Appartement/Buiten 10.JPG'
import Agora9 from '../afbeeldingen/Appartement/Buiten 11.JPG'
import Agora10 from '../afbeeldingen/Appartement/Buiten 12.JPG'
import Agora11 from '../afbeeldingen/Appartement/Zwembad 1.JPG'
import Agora12 from '../afbeeldingen/Appartement/Zwembad 2.JPG'
import Agora13 from '../afbeeldingen/Appartement/Zwembad 3.JPG'
import Agora14 from '../afbeeldingen/Appartement/Zwembad 5.JPG'
import Agora15 from '../afbeeldingen/Appartement/Zwembad 6.jpg'
import Agora16 from '../afbeeldingen/Appartement/Zwembad 7.jpg'
import Agora17 from '../afbeeldingen/Appartement/Zwembad 8.jpg'
import Agora18 from '../afbeeldingen/Appartement/Zwembad 9.jpg'
import Agora19 from '../afbeeldingen/Appartement/Garage 1.JPG'
import Agora20 from '../afbeeldingen/Appartement/Garage 2.JPG'
import Agora21 from '../afbeeldingen/Appartement/Garage 3.jpg'
import Agora22 from '../afbeeldingen/Appartement/Garage 4.JPG'
class Slideshow extends Component {
  constructor() {
    super();
    this.slideRef = React.createRef();
    this.back = this.back.bind(this);
    this.next = this.next.bind(this);
    this.state = {
      current: 0
    };
  }

  back() {
    this.slideRef.current.goBack();
  }

  next() {
    this.slideRef.current.goNext();
  }

  render() {
    const properties = {
      duration: 5000,
      autoplay: true,
      transitionDuration: 1300,
      arrows: true,
      infinite: true,
      easing: "ease",
      // indicators: (i) => <div className="indicator">{i + 1}</div>
    };
    const slideImages = [Agora1, Agora2, Agora3, Agora4, Agora5, Agora6, Agora7, Agora8, Agora9, Agora10, Agora11, Agora12
                        , Agora13, Agora14, Agora15, Agora16, Agora17, Agora18, Agora19, Agora20, Agora21, Agora22];
    return (
      <div className="App">
        <div className="m-auto">
          <Slide ref={this.slideRef} {...properties}>
            {slideImages.map((each, index) => (
              <div key={index} className="flex items-center justify-center bg-cover m-2 mx-auto md:w-1/2">
                <img src={each} alt="sample" className="lazy rounded-xl mx-2" />
              </div>
            ))}
          </Slide>
        </div>
        {/* <div>
          <button onClick={this.back} type="button"
          className="text-xl font-bold bg-gray-300 text-gray-800 dark:text-white hover:text-gray-500 
          hover:bg-gray-200 dark:hover:text-gray-200 px-3 py-1 rounded-lg mx-5 w-28">
            Vorige
          </button>
          <button onClick={this.next} type="button" 
          className="text-xl font-bold bg-gray-300 text-gray-800 dark:text-white hover:text-gray-500 
                hover:bg-gray-200 dark:hover:text-gray-200 px-3 py-1 rounded-lg mx-5 w-28">
            Volgende
          </button>
        </div> */}
      </div>
    );
  }
}

export default Slideshow;