import foto from "../afbeeldingen/Appartement/About us.JPG";

export default function About() {
	return <>
			<div className="my-5 text-center font-lobster text-4xl">
			About us
		</div>
		<div className="sm:grid grid-cols-3 gap-1 font-roboto text-justify text-l m-10">
			<div className="col-span-2 mr-5">
				<p>
					During our search for a second home in the Golden Triangle between Marbella, Estepona and Benahavís, we 
					found Estepona, the garden of the Costa del Sol, to match us the best. It covers an area of 
					137 km² in a fertile valley crossed by small streams and a mountainious area dominated by the 
						<a className="underline hover:text-blue-400 pl-1" target="_blank" rel="noreferrer" href="https://visitestepona.eu/sierra-bermeja/">
							 Sierra Bermeja
						</a>
					, which reaches an elevation of 1,449 m at the peak of Los Reales. Estepona is also well known for it's 
					17 beautiful beaches, which stretch along some 21 km of coastline and is far removed from mass tourism. 
					The town of Estepona has managed to preserve its typical Spanish character and unique identity.
				</p>
				<br />
				<p>
				Our holiday home at Agora in Marbella-Estepona is more than our second home... Relaxation is our trump card.
				We created an interior bright as sea air and combined with brown natural colours, a homely tranquility emanates from the whole.
				<br /> <br />
				In short, experience the wow feeling and enjoy. 
				Practical information and useful tips when serving and on site.
				<br />
				Our lovely neighbours Sita and Thierry will welcome you in person and will show you around...
				</p>
			</div>
			<div>
			<img src={foto} alt="" width="" height="" className="rounded-lg" />
			</div>
		</div>
	</>;
}