import logo from '../afbeeldingen/logo.png'

export default function Footer() {
	return <>
    <div className="pt-12 bg-gradient-to-b from-gray-50 via-blue-100 to-blue-200">
            <footer id="footer" className="relative z-50 dark:bg-gray-900">
                <div tabIndex="0" aria-label="footer" className="ml-5 focus:outline-none border-t border-b border-gray-200 dark:border-gray-700 py-16 md:ml-20">
                    <div className="mx-auto container px-4 xl:px-12 2xl:px-4">
                        <div className="lg:flex">
                            <div className="w-full lg:w-1/2 mb-16 lg:mb-0 flex">
                                <div className="w-full lg:w-1/2 pl-10">
                                    <ul>
                                        <li><a className="focus:outline-none underline  text-xs lg:text-sm leading-none hover:text-brand dark:hover:text-brand text-gray-800 dark:text-gray-50" target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/AGORA/@36.4632151,-5.0969838,14.63z/data=!4m9!1m2!2m1!1sagora+apartments+estepona!3m5!1s0xd0cd57121e2bf73:0x33fdb82d16eac7ec!8m2!3d36.475993!4d-5.0780206!15sChlhZ29yYSBhcGFydG1lbnRzIGVzdGVwb25hkgESYXBhcnRtZW50X2J1aWxkaW5n">Google Maps</a></li>
                                    </ul>
                                </div>
                                <div className="w-full lg:w-1/2 pl-10">
                                    <ul>
                                        <li><a className="focus:outline-none underline text-xs lg:text-sm leading-none hover:text-brand dark:hover:text-brand text-gray-800 dark:text-gray-50" target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=Q-ClU1NYC4I&list=PL20C-xxZxaIxFb8zmvEsFl_ZfEWhCsiEy&index=10">Youtube</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="w-full lg:w-1/2 flex">
                                <div className="w-full lg:w-1/2 pl-10">
                                    <ul>
                                        <li><a href="/Contact" className="underline focus:outline-none text-xs lg:text-sm leading-none hover:text-brand dark:hover:text-brand text-gray-800 dark:text-gray-50">Contact</a></li>
                                    </ul>
                                </div>
                                <div className="w-full lg:w-1/2 pl-10">
                                    <ul>
                                        <li><a href="/About" className="underline focus:outline-none text-xs lg:text-sm leading-none hover:text-brand dark:hover:text-brand text-gray-800 dark:text-gray-50">About us</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-16 flex flex-col justify-center items-center">
                    <div className="focus:outline-none" tabIndex="0" role="link" aria-label="home link">
                          <img src={logo} alt="" width="100" height="100" className="rounded-lg" />
                    </div>
                    <p tabIndex="0" className="focus:outline-none mt-6 text-xs lg:text-sm leading-none text-gray-900 dark:text-gray-50">2022 La Maravilla de Ágora. All Rights Reserved.</p>
                    <p tabIndex="0" className="focus:outline-none mt-6 text-xs lg:text-sm leading-none text-gray-900 dark:text-gray-50">Tony & Katrien, fam. Vermeire-Fevery (Belgium)</p>
                </div>
            </footer>
        </div>
        
    
		
		
		</>;
}