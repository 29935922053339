import React, { useRef } from 'react';
import emailjs from 'emailjs-com';

export default function Contact() {

	
		const form = useRef();
	
		const sendEmail = (e) => {
			e.preventDefault();
	
			emailjs.sendForm('contact_service', 'contact_form', form.current, 'user_inQpeK92lJOZxXfSwSLpO')
				.then((result) => {
						console.log(result.text);
				}, (error) => {
						console.log(error.text);
				});
		};

		const refresh = () => {
			setTimeout(function(){
				return window.location.reload();
			}, 2000);
		}

	return <>
		<div className="my-5 text-center font-lobster text-4xl">
		Contact
	</div>
	<div className="px-8 pb-4 font-roboto">
		<div className="m-4 text-center">
		<p>Please fill in the form below and click on 'send'</p>
			<p>We will reply as soon as possible</p>
		</div>
		<form ref={form} onSubmit={sendEmail} method="post" encType="text/plain" 
			className="border-solid border-black border-2 rounded-lg p-4 flex justify-center md:items-center  max-w-screen-md m-auto">
			<fieldset>
				<div className="flex justify-center">
				<legend className="font-bold text-xl mb-4">Contact form</legend>
				</div>
				<div>
					<div className="grid md:grid-cols-2">
						<div>
							<label htmlFor="voornaam" className="float-left text-right mr-4 w-16 sm:w-24 sm:text-md">Fistname:</label>
							<input type="text" name="voornaam" id="voornaam" placeholder="Firstname" required 
								className="bg-gray-200 border border-solid border-gray-400 mb-2"/>
							<sup className="text-yellow-600 font-bold">*</sup>
						</div>
						<div>
							<label htmlFor="achternaam" className="float-left w-16 sm:w-24 text-sm sm:text-base text-right mr-4">Lastname:</label>
							<input type="text" name="achternaam" id="achternaam" placeholder="Lastname" required 
								className="bg-gray-200 border border-solid border-gray-400 mb-4"/>
							<sup className="text-yellow-600 font-bold">*</sup>
						</div>
					</div>
					<div>
					<div className="grid md:grid-cols-2">
							<div>
								<label htmlFor="land" className="float-left w-16 sm:w-24 text-sm sm:text-base text-right mr-4">Country:</label>
								<input type="text" name="land" id="land" placeholder="Country" required 
									className="bg-gray-200 border border-solid border-gray-400 md:w-30 mb-2"/>
								<sup className="text-yellow-600 font-bold">*</sup>
							</div>
							<div>
								<label htmlFor="stad" className="float-left w-16 sm:w-24 text-sm sm:text-base text-right mr-4">City:</label>
								<input type="text" name="stad" id="stad" placeholder="City" required 
									className="bg-gray-200 border border-solid border-gray-400 md:w-30 mb-4"/>
								<sup className="text-yellow-600 font-bold">*</sup>
							</div>
						</div>
					</div>
					<div className="grid md:grid-cols-2">
					<div>
							<label htmlFor="gsm" className="float-left w-16 sm:w-24 text-sm sm:text-base text-right mr-4">Phone:</label>
							<input type="text" name="gsm" id="gsm" placeholder="Phone number" required 
								className="bg-gray-200 border border-solid border-gray-400 md:w-30 mb-2"/>
							<sup className="text-yellow-600 font-bold">*</sup>
						</div>
						<div>
						<label htmlFor="email" className="float-left w-16 sm:w-24 text-sm sm:text-base text-right mr-4">Email:</label>
							<input type="email" name="email" id="email" obligatory placeholder="Email" className="bg-gray-200 border border-solid border-gray-400 mb-4"/>
							<sup className="text-yellow-600 font-bold">*</sup>
						</div>
					</div>
					<div>
						<label htmlFor="bericht" className="float-left w-24 text-sm sm:text-base text-right mr-4">Message:</label>
						<textarea required name="bericht" id="bericht" rows="6" cols="30" placeholder="Type here..." 
							className="bg-gray-200 border border-solid border-gray-400 mb-2"/>
						<sup className="text-yellow-600 font-bold">*</sup>
					</div>
					<div>
						<input type="submit" value="Send" onClick={refresh} className="text-xl font-bold bg-gray-300 text-gray-800 dark:text-white md:text-xl 
						hover:text-gray-500 hover:bg-gray-200 dark:hover:text-gray-200 px-3 py-1 rounded-lg ml-28"/>
					</div>
				</div>
				<p className="text-xs mt-2">Fields with <sup className="text-yellow-600 font-bold">*</sup> are obligatory</p>
			</fieldset>
		</form>
	</div>
	</>;
}
