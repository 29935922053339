import terras from '../afbeeldingen/Appartement/Terras 4.jpg'
import keuken from '../afbeeldingen/Appartement/keuken 4.jpg'
import living from '../afbeeldingen/Appartement/Living 3.JPG'
import masterbedroom from '../afbeeldingen/Appartement/Master bedroom 3.jpg'
import slaapkamer2 from '../afbeeldingen/Appartement/Slaapkamer 1.jpg'
import badkamer1 from '../afbeeldingen/Appartement/Badkamer 1 3.jpg'
import badkamer2 from '../afbeeldingen/Appartement/Badkamer 2 5.jpg'
import domein from '../afbeeldingen/Appartement/Zwembad 8.jpg'

export default function Appartement() {

	return <>
		<div className="my-5 text-center font-lobster text-4xl">
			Apartment
		</div>
		<div className="font-roboto text-justify text-l m-10">
			<p>
			The cutting-edge architectural styling by Serrano Font is complemented by intelligent interior 
			distribution which allows natural light to flow into all rooms with porcelain floors.
			The flat has open spacious interior with floor-to-ceiling sliding glass doors in a modern design 
			that leads to generous terraces for stunning open views.

			</p>
		</div>
		<div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 place-items-center content-center m-4">
			<a href="/Slaapkamer1">
				<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
					<div className="flex items-center justify-center bg-cover m-2">
						<img src={masterbedroom} alt="" width="300" height="300" className="rounded-md"/>
					</div>
					<div className="text-center m-3 text-xl sm:text-2xl">
						Master bedroom
					</div>
				</button>
			</a>
			<a href="/Badkamer1">
				<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
					<div className="flex items-center justify-center bg-cover m-2">
						<img src={badkamer1} alt="" width="300" height="300" className="rounded-md"/>
					</div>
					<div className="text-center m-3 text-xl sm:text-2xl">
						Bathroom 1
					</div>
				</button>
			</a>
			<a href="/Slaapkamer2">
				<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
					<div className="flex items-center justify-center bg-cover m-2">
						<img src={slaapkamer2} alt="" width="300" height="300" className="rounded-md"/>
					</div>
					<div className="text-center m-3 text-xl sm:text-2xl">
						Second bedroom
					</div>
				</button>
			</a>
			<a href="/Badkamer2">
				<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
					<div className="flex items-center justify-center bg-cover m-2">
						<img src={badkamer2} alt="" width="300" height="300" className="rounded-md"/>
					</div>
					<div className="text-center m-3 text-xl sm:text-2xl">
						Bathroom 2
					</div>
				</button>
			</a>
			<a href="/Living">
				<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
					<div className="flex items-center justify-center bg-cover m-2">
						<img src={living} alt="" width="300" height="300" className="rounded-md"/>
					</div>
					<div className="text-center m-3 text-xl sm:text-2xl">
						Livingroom
					</div>
				</button>
			</a>
			<a href="/Terras">
				<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
					<div className="flex items-center justify-center bg-cover m-2">
						<img src={terras} alt="" width="300" height="300" className="rounded-md"/>
					</div>
					<div className="text-center m-3 text-xl sm:text-2xl">
						Terrace
					</div>
				</button>
			</a>
			<a href="/Keuken">
				<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
					<div className="flex items-center justify-center bg-cover m-2">
						<img src={keuken} alt="" width="300" height="300" className="rounded-md"/>
					</div>
					<div className="text-center m-3 text-xl sm:text-2xl">
						Kitchen
					</div>
				</button>
			</a>
			<a href="/Domein">
				<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
					<div className="flex items-center justify-center bg-cover m-2">
						<img src={domein} alt="" width="300" height="300" className="rounded-md"/>
					</div>
					<div className="text-center m-3 text-xl sm:text-2xl">
						Domain
					</div>
				</button>
			</a>
		</div>
	</>
}