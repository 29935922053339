import './App.css';
import Navbar from './components/Navbar';
import Startscherm from './components/Startscherm';
import Contact from './components/Contact';
import About from './components/about';
import Appartement from './components/appartement';
import Omgeving from './components/omgeving';
import Boeken from './components/boeken';
import Prijzen from './components/Prijzen';
import Slaapkamer1 from './components/slaapkamer1';
import Slaapkamer2 from './components/slaapkamer2';
import Badkamer1 from './components/badkamer1';
import Badkamer2 from './components/badkamer2';
import Living from './components/living';
import Keuken from './components/keuken';
import Domein from './components/domein';
import Terras from './components/terras';
import Footer from './components/Footer';
import RouteChangeTracker from './components/RouteChangeTracker'
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import ReactGA from 'react-ga';

const TRACKING_ID = "UA-241935340-1";
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
      <>
        <Router>
          <Navbar/>
            <Switch>
                <Route exact path="/">
                  <Startscherm />
                </Route>
                <Route exact path="/Contact">
                  <Contact />
                </Route>
                <Route exact path="/Appartement">
                  <Appartement />
                </Route>
                <Route exact path="/Omgeving">
                  <Omgeving />
                </Route>
                <Route exact path="/Boeken">
                  <Boeken />
                </Route>
                <Route exact path="/Prijzen">
                  <Prijzen />
                </Route>
                <Route exact path="/Contact">
                  <Contact />
                </Route>
                <Route exact path="/About">
                  <About />
                </Route>
                <Route exact path="/Slaapkamer1">
                  <Slaapkamer1 />
                </Route>
                <Route exact path="/Slaapkamer2">
                  <Slaapkamer2 />
                </Route>
                <Route exact path="/Badkamer1">
                  <Badkamer1 />
                </Route>
                <Route exact path="/Badkamer2">
                  <Badkamer2 />
                </Route>
                <Route exact path="/Terras">
                  <Terras />
                </Route>
                <Route exact path="/Living">
                  <Living />
                </Route>
                <Route exact path="/Keuken">
                  <Keuken />
                </Route>
                <Route exact path="/Domein">
                  <Domein />
                </Route>
            </Switch>
          <Footer /> 
          <RouteChangeTracker />
        </Router>
      </>
  );
}

export default App;
