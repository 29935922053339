import Slideshow from './Slideshow_keuken';


export default function Keuken() {
	return <>
		<div className="grid grid-cols-10 mt-3">
			<div>
				<a href="/Appartement" className="font-roboto text-xl ml-1 sm:ml-5 py-1 px-3 font-bold border-solid border-2 border-black rounded-lg hover:bg-gray-200">Back</a>
			</div>
			<div className="mb-5 col-span-8 text-center font-lobster text-3xl sm:text-4xl">
				Kitchen
				</div>
			<div className="col-span-10 mx-5">
				<Slideshow />
			</div>
			<div className="font-roboto col-span-10 text-l mx-10 my-3">
				<p>
				A modern kitchen full of light with all cooking comforts:
Nespresso coffee machine, oven and microwave, full size dishwasher, fridge and freezer, cooker.
The dining table is spacious for 4 people. Debating, dining with a view of the natural surroundings is an asset.
Finally, there is a separate storage closet with washing machine.
				</p>
			</div>
		</div>
	</>
}