import React, { useRef, useState, useEffect } from 'react';
import emailjs from 'emailjs-com';

export default function Boeken() {
	const [checkin, setCheckin] = useState(new Date());
	const [checkout, setCheckout] = useState();

		const form = useRef();
		const now = new Date();
		let mincheckin = "";
		mincheckin = now.getFullYear();
		mincheckin += "-";
		if (now.getMonth() < 10) {
			mincheckin += "0";
		}
		mincheckin += now.getMonth() + 1;
		mincheckin += "-";
		if (now.getDate() < 10) {
			mincheckin += "0";
		}
		mincheckin += now.getDate() + 1;
		mincheckin = mincheckin.toString();

		useEffect(() => {
			let checkinD = new Date(checkin);
			let mincheckout = "";
			mincheckout = checkinD.getFullYear()
			mincheckout += "-";
			if (checkinD.getMonth() < 10) {
				mincheckout += "0";
			}
			mincheckout += checkinD.getMonth() + 1;
			mincheckout += "-";
			if (checkinD.getDate() < 10) {
				mincheckout += "0";
			}
			mincheckout += checkinD.getDate() + 3;
			mincheckout = mincheckout.toString();
			setCheckout(mincheckout);
		}, [checkout, setCheckout, checkin])

	
		const sendEmail = (e) => {
			e.preventDefault();
	
			emailjs.sendForm('contact_service', 'nieuwe_boeking', form.current, 'user_inQpeK92lJOZxXfSwSLpO')
				.then((result) => {
						console.log(result.text);
				}, (error) => {
						console.log(error.text);
				});
		};

		const refresh = () => {
			setTimeout(function(){
				return window.location.reload();
			}, 2000);
		}

	return <>
	<div className="my-5 text-center font-lobster text-4xl">
		Booking
	</div>
	<div className="px-8 pb-4 font-roboto">
		<div className="m-4 text-center">
			<p>Please fill in the form below and click on 'send'</p>
			<p>We will reply as soon as possible</p>
		</div>
		<form ref={form} onSubmit={sendEmail} method="post" encType="text/plain" 
			className="border-solid border-black border-2 rounded-lg p-4 flex justify-center md:items-center  max-w-screen-md m-auto">
			<fieldset>
				<div className="flex justify-center">
				<legend className="font-bold text-xl mb-4">Booking request</legend>
				</div>
				<div>
					<div className="grid md:grid-cols-2">
						<div>
							<label htmlFor="voornaam" className="float-left text-right mr-4 w-16 sm:w-24 sm:text-md">Firstname:</label>
							<input type="text" name="voornaam" id="voornaam" placeholder="Firstname" required 
								className="bg-gray-200 border border-solid border-gray-400 mb-2"/>
							<sup className="text-yellow-600 font-bold">*</sup>
						</div>
						<div>
							<label htmlFor="achternaam" className="float-left w-16 sm:w-24 text-sm sm:text-base text-right mr-4">Lastname:</label>
							<input type="text" name="achternaam" id="achternaam" placeholder="Lastname" required 
								className="bg-gray-200 border border-solid border-gray-400 mb-4"/>
							<sup className="text-yellow-600 font-bold">*</sup>
						</div>
					</div>
					<div>
						<div>
							<label htmlFor="adres" className="float-left w-16 sm:w-24 text-sm sm:text-base text-right mr-4">Address:</label>
							<input type="text" name="adres" id="adres" placeholder="Address" required 
								className="bg-gray-200 border border-solid border-gray-400 md:w-4/5 mb-2"/>
							<sup className="text-yellow-600 font-bold">*</sup>
						</div>
						<div className="grid md:grid-cols-2">
							<div>
								<label htmlFor="land" className="float-left w-16 sm:w-24 text-sm sm:text-base text-right mr-4">Country:</label>
								<input type="text" name="land" id="land" placeholder="Country" required 
									className="bg-gray-200 border border-solid border-gray-400 md:w-30 mb-2"/>
								<sup className="text-yellow-600 font-bold">*</sup>
							</div>
							<div>
								<label htmlFor="stad" className="float-left w-16 sm:w-24 text-sm sm:text-base text-right mr-4">City:</label>
								<input type="text" name="stad" id="stad" placeholder="City" required 
									className="bg-gray-200 border border-solid border-gray-400 md:w-30 mb-4"/>
								<sup className="text-yellow-600 font-bold">*</sup>
							</div>
						</div>
					</div>
					<div className="grid md:grid-cols-2">
					<div>
							<label htmlFor="gsm" className="float-left w-16 sm:w-24 text-sm sm:text-base text-right mr-4">Phone nr:</label>
							<input type="number" name="gsm" id="gsm" placeholder="Phone number" required 
								className="bg-gray-200 border border-solid border-gray-400 md:w-30 mb-2"/>
							<sup className="text-yellow-600 font-bold">*</sup>
						</div>
						<div>
							<label htmlFor="email" className="float-left w-16 sm:w-24 text-sm sm:text-base text-right mr-4">Email:</label>
							<input type="email" name="email" id="email" obligatory placeholder="Email" className="bg-gray-200 border border-solid border-gray-400 mb-4"/>
							<sup className="text-yellow-600 font-bold">*</sup>
						</div>
					</div>
					<div className="grid md:grid-cols-2">
					<div>
							<label htmlFor="incheckdatum" className="float-left w-16 sm:w-24 text-sm sm:text-base text-right mr-4">Check-in:</label>
							<input type="date" name="incheckdatum" id="incheckdatum" required min={mincheckin} onChange={e => { setCheckin(e.target.value); }} className="bg-gray-200 border border-solid border-gray-400 md:w-30 mb-2"/>
							<sup className="text-yellow-600 font-bold">*</sup>
						</div>
						<div>
							<label htmlFor="uitcheckdatum" className="float-left w-16 sm:w-24 text-sm sm:text-base text-right mr-4">Check-out:</label>
							<input type="date" name="uitcheckdatum" id="uitcheckdatum" required min={checkout} className="bg-gray-200 border border-solid border-gray-400 md:w-30 mb-2"/>
							<sup className="text-yellow-600 font-bold">*</sup>
						</div>
					</div>
					<div className="grid md:grid-cols-2">
					<div>
							<label htmlFor="aantalPersonen" className="float-left w-16 sm:w-24 text-sm sm:text-base text-right mr-4">Persons:</label>
							<select id="aantalPersonen" name="aantalPersonen" className="bg-gray-200 border border-solid border-gray-400 md:w-30 mb-2">
								<option value="1">1</option>
								<option value="2">2</option>
								<option value="3">3</option>
								<option value="4">4</option>
							</select>
							<sup className="text-yellow-600 font-bold">*</sup>
						</div>
						<div>
							<label htmlFor="taal" className="float-left w-16 sm:w-24 text-sm sm:text-base text-right mr-4">Language:</label>
							<select id="taal" name="taal" className="bg-gray-200 border border-solid border-gray-400 md:w-30 mb-2">
								<option value="Engels">English</option>
								<option value="Nederlands">Dutch</option>
								<option value="Frans">French</option>
								<option value="Duits">German</option>
								<option value="Spaans">Spanish</option>
							</select>							
							<sup className="text-yellow-600 font-bold">*</sup>
						</div>
					</div>
					<div>
						<label htmlFor="bericht" className="float-left w-24 text-sm sm:text-base text-right mr-4">Message:</label>
						<textarea name="bericht" id="bericht" rows="6" cols="30" placeholder="Type here..." 
							className="bg-gray-200 border border-solid border-gray-400 mb-2"/>
					</div>
					<div>
						<label htmlFor="find" className="float-left w-24 sm:w-24 text-sm sm:text-base text-right mr-4">How did you find us?</label>
								<select id="find" name="find" className="bg-gray-200 border border-solid border-gray-400 md:w-30 mb-2">
									<option value="Family">Family</option>
									<option value="Friends">Friends</option>
									<option value="Search">Search</option>
									<option value="Others">Others</option>
								</select>	
					</div>
					<div>
						<input type="submit" value="Send" onClick={refresh} className="text-xl font-bold bg-gray-300 text-gray-800 dark:text-white md:text-xl 
						hover:text-gray-500 hover:bg-gray-200 dark:hover:text-gray-200 px-3 py-1 rounded-lg ml-28"/>
					</div>
				</div>
				<p className="text-xs mt-2">Fields with <sup className="text-yellow-600 font-bold">*</sup> are obligatory</p>
			</fieldset>
		</form>
	</div>
	</>;
}