import estepona from '../afbeeldingen/estepona.jpg'
import puertobanus from '../afbeeldingen/puerto banus.jpg'
import marbella from '../afbeeldingen/marbella.jpg'
import casares from '../afbeeldingen/casares.jpg'
import gibraltar from '../afbeeldingen/gibraltar.jpg'
import ronda from '../afbeeldingen/ronda.jpg'
import malaga from '../afbeeldingen/malaga.jpg'
import selwoaventura from '../afbeeldingen/selwo aventura.jpg'
import beaches from '../afbeeldingen/beaches.jpg'
import sierrabermeja from '../afbeeldingen/sierra bermeja.jpg'
import camenitodelrey from '../afbeeldingen/camenito del rey.jpg'
import karting from '../afbeeldingen/karting.jpg'
import watersports from '../afbeeldingen/watersports.jpg'
import costadelgolf from '../afbeeldingen/costa del golf.jpg'
import markets from '../afbeeldingen/markets.jpg'
import buggytour from '../afbeeldingen/buggy tour.jpg'
import sailingtrips from '../afbeeldingen/sailing trips.jpg'
import tarifa from '../afbeeldingen/tarifa.jpg'
import bullfighting from '../afbeeldingen/bullfighting.jpg'
import flamenco from '../afbeeldingen/flamenco.jpg'
import kitesurfing from '../afbeeldingen/kitesurfing.jpg'
import racebike from '../afbeeldingen/racebikes.jpg'
import benahavis from '../afbeeldingen/benahavis.png'
import aqualand from '../afbeeldingen/aqualand.jpg'

export default function Omgeving() {
	return <>
		<div className="my-5 text-center font-lobster text-4xl">
			Region
		</div>
		<div className="font-roboto text-center text-xl m-10">
			<p> points of interest around our location at a glance
			</p>
		</div>
		<div>
		<div className="text-3xl font-roboto text-bold text-center sm:text-left sm:mx-14 my-0">
				Cities
				</div>
			<div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 place-items-center content-center m-4">
				<a href="https://visitestepona.eu" target="_blank" rel="noreferrer">
					<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
						<div className="flex items-center justify-center bg-cover m-2">
							<img src={estepona} alt="" width="300" height="300" className="rounded-md"/>
						</div>
						<div className="grid grid-cols-2">
							<div className="text-left ml-3 mt-3 text-xl md:text-2xl">
							Estepona
							</div>
							<div className="text-right mr-6 text-md sm:text-xl">
							12km
							</div>
						</div>
					</button>
				</a>
				<a href="https://puerto-banus.com" target="_blank" rel="noreferrer">
					<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
						<div className="flex items-center justify-center bg-cover m-2">
							<img src={puertobanus} alt="" width="300" height="300" className="rounded-md"/>
						</div>
						<div className="grid grid-cols-4">
							<div className="text-left col-span-3 ml-3 mt-3 text-xl md:text-2xl">
							Puerto Banús
							</div>
							<div className="text-right mr-6 text-md sm:text-xl">
							17km
							</div>
						</div>
					</button>
				</a>
				<a href="https://www.turismobenahavis.org/" target="_blank" rel="noreferrer">
					<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
						<div className="flex items-center justify-center bg-cover m-2">
							<img src={benahavis} alt="" width="300" height="300" className="rounded-md"/>
						</div>
						<div className="grid grid-cols-4">
							<div className="text-left col-span-3 ml-3 mt-3 text-xl md:text-2xl">
							Benahavís
							</div>
							<div className="text-right mr-6 text-md sm:text-xl">
							18km
							</div>
						</div>
					</button>
				</a>
				<a href="https://www.visitcostadelsol.com/things-to-do/marbella-p9851" target="_blank" rel="noreferrer">
					<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
						<div className="flex items-center justify-center bg-cover m-2">
							<img src={marbella} alt="" width="300" height="300" className="rounded-md"/>
						</div>
						<div className="grid grid-cols-2">
							<div className="text-left ml-3 mt-3 text-xl md:text-2xl">
							Marbella
							</div>
							<div className="text-right mr-6 text-md sm:text-xl">
							26km
							</div>
						</div>
					</button>
				</a>
				<a href="https://www.andalucia.com/casares/home.htm" target="_blank" rel="noreferrer">
					<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
						<div className="flex items-center justify-center bg-cover m-2">
							<img src={casares} alt="" width="300" height="300" className="rounded-md"/>
						</div>
						<div className="grid grid-cols-2">
							<div className="text-left ml-3 mt-3 text-xl md:text-2xl">
							Casares
							</div>
							<div className="text-right mr-6 text-md sm:text-xl">
							27km
							</div>
						</div>
					</button>
				</a>
				<a href="https://www.visitgibraltar.gi" target="_blank" rel="noreferrer">
					<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
						<div className="flex items-center justify-center bg-cover m-2">
							<img src={gibraltar} alt="" width="300" height="300" className="rounded-md"/>
						</div>
						<div className="grid grid-cols-2">
							<div className="text-left ml-3 mt-3 text-xl md:text-2xl">
							Gibraltar
							</div>
							<div className="text-right mr-6 text-md sm:text-xl">
							59km
							</div>
						</div>
					</button>
				</a>
				<a href="https://turismoderonda.es/es/ronda/" target="_blank" rel="noreferrer">
					<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
						<div className="flex items-center justify-center bg-cover m-2">
							<img src={ronda} alt="" width="300" height="300" className="rounded-md"/>
						</div>
						<div className="grid grid-cols-2">
							<div className="text-left ml-3 mt-3 text-xl md:text-2xl">
							Ronda
							</div>
							<div className="text-right mr-6 text-md sm:text-xl">
							64km
							</div>
						</div>
					</button>
				</a>
				<a href="https://www.spain.info/en/destination/tarifa/" target="_blank" rel="noreferrer">
					<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
						<div className="flex items-center justify-center bg-cover m-2">
							<img src={tarifa} alt="" width="300" height="300" className="rounded-md"/>
						</div>
						<div className="grid grid-cols-4">
							<div className="text-left col-span-3 ml-3 mt-3 text-xl md:text-2xl">
								Tarifa
							</div>
							<div className="text-right mr-6 text-md sm:text-xl">
							82km
							</div>
						</div>
					</button>
				</a>
				<a href="https://visita.malaga.eu/es/" target="_blank" rel="noreferrer">
					<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
						<div className="flex items-center justify-center bg-cover m-2">
							<img src={malaga} alt="" width="300" height="300" className="rounded-md"/>
						</div>
						<div className="grid grid-cols-2">
							<div className="text-left ml-3 mt-3 text-xl md:text-2xl">
							Málaga
							</div>
							<div className="text-right mr-6 text-md sm:text-xl">
							84km
							</div>
						</div>
					</button>
				</a>
			</div>
		</div>
		<div> 
		<div className="text-3xl font-roboto text-bold text-center sm:text-left sm:mx-14 my-0">
				Nature
				</div>
			<div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 place-items-center content-center m-4">
				<a href="https://www.selwo.es/" target="_blank" rel="noreferrer">
					<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
						<div className="flex items-center justify-center bg-cover m-2">
							<img src={selwoaventura} alt="" width="300" height="300" className="rounded-md"/>
						</div>
						<div className="grid grid-cols-5">
							<div className="text-left col-span-4 ml-3 mt-3 text-xl md:text-2xl">
							Selwo Aventura
							</div>
							<div className="text-right mr-6 text-md sm:text-xl">
							3km
							</div>
						</div>
					</button>
				</a>
				<a href="https://blog.fuertehoteles.com/en/destinations/best-beaches-estepona/" target="_blank" rel="noreferrer">
					<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
						<div className="flex items-center justify-center bg-cover m-2">
							<img src={beaches} alt="" width="300" height="300" className="rounded-md"/>
						</div>
						<div className="grid grid-cols-2">
							<div className="text-left ml-3 mt-3 text-xl md:text-2xl">
							Beaches
							</div>
							<div className="text-right mr-6 text-md sm:text-xl">
							4km
							</div>
						</div>
					</button>
				</a>
				<a href="https://visitestepona.eu/sierra-bermeja/" target="_blank" rel="noreferrer">
					<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
						<div className="flex items-center justify-center bg-cover m-2">
							<img src={sierrabermeja} alt="" width="300" height="300" className="rounded-md"/>
						</div>
						<div className="grid grid-cols-4">
							<div className="text-left col-span-3 ml-3 mt-3 text-xl md:text-2xl">
								Sierra Bermeja
							</div>
							<div className="text-right mr-6 text-md sm:text-xl">
							25km
							</div>
						</div>
					</button>
				</a>
				<a href="https://www.caminitodelrey.info" target="_blank" rel="noreferrer">
					<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
						<div className="flex items-center justify-center bg-cover m-2">
							<img src={camenitodelrey} alt="" width="300" height="300" className="rounded-md"/>
						</div>
						<div className="grid grid-cols-4">
							<div className="text-left col-span-3 ml-3 mt-3 md:text-md sm:text-xl">
							Caminito del Rey
							</div>
							<div className="text-right mr-6 text-md sm:text-xl">
							94km
							</div>
						</div>
					</button>
				</a>
			</div>
		</div>
		<div>
		<div className="text-3xl font-roboto text-bold text-center sm:text-left sm:mx-14 my-0">
				Adventure
				</div>
			<div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 place-items-center content-center m-4">
			<a href="https://www.rentaroadbikemarbella.com" target="_blank" rel="noreferrer">
					<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
						<div className="flex items-center justify-center bg-cover m-2">
							<img src={racebike} alt="" width="300" height="300" className="rounded-md"/>
						</div>
						<div className="grid grid-cols-3">
							<div className="text-left col-span-2 ml-3 mt-3 text-xl md:text-2xl">
							Road/MTB
							</div>
							<div className="text-right mr-6 text-md sm:text-xl">
							Delivery
							</div>
						</div>
					</button>
				</a>
			<a href="https://www.andalucia.org/en/estepona-golf-la-resina-golf-country-club" target="_blank" rel="noreferrer">
					<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
						<div className="flex items-center justify-center bg-cover m-2">
							<img src={costadelgolf} alt="" width="300" height="300" className="rounded-md"/>
						</div>
						<div className="grid grid-cols-3">
							<div className="text-left col-span-2 ml-3 mt-3 text-xl md:text-2xl">
							Golf
							</div>
							<div className="text-right mr-6 text-md sm:text-xl">
							2km
							</div>
						</div>
					</button>
				</a>
			<a href="https://www.buggyfunmarbella.com" target="_blank" rel="noreferrer">
					<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
						<div className="flex items-center justify-center bg-cover m-2">
							<img src={buggytour} alt="" width="300" height="300" className="rounded-md"/>
						</div>
						<div className="grid grid-cols-4">
							<div className="text-left col-span-3 ml-3 mt-3 text-xl md:text-2xl">
							Buggy tour
							</div>
							<div className="text-right mr-6 text-md sm:text-xl">
							6km
							</div>
						</div>
					</button>
				</a>
				<a href="https://www.kartfun.es/circuito-de-estepona/" target="_blank" rel="noreferrer">
					<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
						<div className="flex items-center justify-center bg-cover m-2">
							<img src={karting} alt="" width="300" height="300" className="rounded-md"/>
						</div>
						<div className="grid grid-cols-2">
							<div className="text-left ml-3 mt-3 text-xl md:text-2xl">
							Karting
							</div>
							<div className="text-right mr-6 text-md sm:text-xl">
							12km
							</div>
						</div>
					</button>
				</a>
				<a href="https://catamaranesestepona.com/es" target="_blank" rel="noreferrer">
					<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
						<div className="flex items-center justify-center bg-cover m-2">
							<img src={sailingtrips} alt="" width="300" height="300" className="rounded-md"/>
						</div>
						<div className="grid grid-cols-4">
							<div className="text-left  col-span-3 ml-3 mt-3 text-xl md:text-2xl">
							Sailing Trips
							</div>
							<div className="text-right mr-6 text-md sm:text-xl">
							14km
							</div>
						</div>
					</button>
				</a>
				<a href="https://chartersandwatersports.com" target="_blank" rel="noreferrer">
					<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
						<div className="flex items-center justify-center bg-cover m-2">
							<img src={watersports} alt="" width="300" height="300" className="rounded-md"/>
						</div>
						<div className="grid grid-cols-2">
							<div className="text-left ml-3 mt-3 text-xl md:text-2xl">
							Watersports
							</div>
							<div className="text-right mr-6 text-md sm:text-xl">
							15km
							</div>
						</div>
					</button>
				</a>
				<a href="https://www.aqualand.es/torremolinos/" target="_blank" rel="noreferrer">
					<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
						<div className="flex items-center justify-center bg-cover m-2">
							<img src={aqualand} alt="" width="300" height="300" className="rounded-md"/>
						</div>
						<div className="grid grid-cols-2">
							<div className="text-left ml-3 mt-3 text-xl md:text-2xl">
							Aqualand
							</div>
							<div className="text-right mr-6 text-md sm:text-xl">
							69km
							</div>
						</div>
					</button>
				</a>
				<a href="https://www.checkyeti.com/en/kite-and-windsurfing/spain/tarifa/windsurfing-kitesurfing?productTypes=kitesurfing&sourceId=cpc&campaignId=10500917675&adId=592553560923&keyword=kitesurfing%20tarifa&googleHeadlineCategory=RSA&gclid=Cj0KCQjwof6WBhD4ARIsAOi65aiVGYifLTnqYFPezY6bS09iPXT14TAYqsEPcUod8HWQJOgCBY1NvqQaAgmUEALw_wcB" target="_blank" rel="noreferrer">
					<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
						<div className="flex items-center justify-center bg-cover m-2">
							<img src={kitesurfing} alt="" width="300" height="300" className="rounded-md"/>
						</div>
						<div className="grid grid-cols-2">
							<div className="text-left ml-3 mt-3 text-xl md:text-2xl">
							Kitesurfing
							</div>
							<div className="text-right mr-6 text-md sm:text-xl">
							82km
							</div>
						</div>
					</button>
				</a>
			</div>
		</div>
		<div>
			<div className="text-3xl font-roboto text-bold text-center sm:text-left sm:mx-14 my-0">
				Events
			</div>
			<div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 place-items-center content-center m-4">
				<a href="https://www.spanish-fiestas.com/costa-del-sol/markets/" target="_blank" rel="noreferrer">
					<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
						<div className="flex items-center justify-center bg-cover m-2">
							<img src={markets} alt="" width="300" height="300" className="rounded-md"/>
						</div>
						<div className="grid grid-cols-2">
							<div className="text-left ml-3 mt-3 text-xl md:text-2xl">
							Markets
							</div>
							<div className="text-right mr-6 text-md sm:text-xl">
							12km
							</div>
						</div>
					</button>
				</a>
				<a href="https://www.getyourguide.nl/estepona-l2164/estepona-toegangsticket-voor-andalusische-flamencoshow-met-drankje-t420634/" target="_blank" rel="noreferrer">
					<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
						<div className="flex items-center justify-center bg-cover m-2">
							<img src={flamenco} alt="" width="300" height="300" className="rounded-md"/>
						</div>
						<div className="grid grid-cols-2">
							<div className="text-left ml-3 mt-3 text-xl md:text-2xl">
							Flamenco
							</div>
							<div className="text-right mr-6 text-md sm:text-xl">
							13km
							</div>
						</div>
					</button>
				</a>
				<a href="https://www.bookwow.es/events-and-shows/BullfightinginAndalusia/Events-BullfightinginAndalusia.html" target="_blank" rel="noreferrer">
					<button className="text-left m-4 border-solid border-2 border-black rounded-lg hover:bg-gray-200">
						<div className="flex items-center justify-center bg-cover m-2">
							<img src={bullfighting} alt="" width="300" height="300" className="rounded-md"/>
						</div>
						<div className="grid grid-cols-2">
							<div className="text-left ml-3 mt-3 text-xl md:text-2xl">
							Bullfighting
							</div>
							<div className="text-right mr-6 text-md sm:text-xl">
							27km
							</div>
						</div>
					</button>
				</a>
			</div>
			<div className="font-roboto text-left text-l m-10">
				<p>
					Other information that may be useful during your stay (e.g. restaurants, bars, ...) you will find on the spot in the apartment.
				</p>
			</div>
		</div>
	</>
}