import Slideshow from './Slideshow_badkamer2';


export default function Badkamer2() {
	return <>
		<div className="grid grid-cols-10 mt-3">
			<div>
				<a href="/Appartement" className="font-roboto text-xl ml-1 sm:ml-5 py-1 px-3 font-bold border-solid border-2 border-black rounded-lg hover:bg-gray-200">Back</a>
			</div>
			<div className="mb-5 col-span-8 text-center font-lobster text-3xl sm:text-4xl">
				Bathroom 2
				</div>
			<div className="col-span-10 mx-5">
				<Slideshow />
			</div>
			<div className="font-roboto col-span-10 text-l mx-10 my-3">
				<p>
				The bathrooms are equipped with every comfort. 
 A spacious shower, large washbasin with chest of drawers. 
 There is also a hairdryer available and a toilet in every bathroom.

				</p>
			</div>
		</div>
	</>
}