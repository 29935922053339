import Slideshow from './Slideshow_domein';


export default function Domein() {
	return <>
		<div className="grid grid-cols-10 mt-3">
			<div>
				<a href="/Appartement" className="font-roboto text-xl ml-1 sm:ml-5 py-1 px-3 font-bold border-solid border-2 border-black rounded-lg hover:bg-gray-200">Back</a>
			</div>
			<div className="mb-5 col-span-8 text-center font-lobster text-3xl sm:text-4xl">
				Domain
				</div>
			<div className="col-span-10 mx-5">
				<Slideshow />
			</div>
			<div className="font-roboto col-span-10 text-l mx-10 my-3">
				<p>
				Ágora is a small urbanisation in a green quiet area. It is fully enclosed and equipped with camera surveillance. The air conditioning is discreetly installed in the front garden and causes no noice.
				You can enjoy a Mediterranean garden in a modern complex. 
				Through the well-kept paths you can easily walk to the infinity pool and children's pool, sunbeds and parasols are at your disposal.
				Finally, don't be alarmed if you hear lions roaring from your terrace in the evening....Selwo is the protected animal park that provides the beautiful view. Come and experience it for yourself....
				</p>
			</div>
		</div>
	</>
}