export default function Prijzen() {
	return <>
		<div className="mt-5">
			<div className="mb-5 text-center font-lobster text-4xl">
				Prices
			</div>
			<div className="flex items-center justify-center rounded-lg px-9 md:px-40 lg:px-96">
				<div className="font-roboto text-justify text-l mx-10 mt-5">
					Only available on request...
				</div>
			</div>
			<div className="font-roboto text-justify text-l mx-10 mt-5">
			{/* <p className="text-sm italic">
				For now, prices are only available on request...
			</p> */}
			<p>
			Due to the quiet nature of the urbanisation, groups of young people under the age of 25 having parties or exceeding the maximum capacity of the property are not accepted. 
			Failure to comply with these rules will result in automatic cancellation of the reservation and immediate eviction without compensation.
			</p>
		</div>
		</div>
		<div className="grid place-items-center content-center">
				<a className="p-4" href="/Boeken">
					<button className="text-gray-700 px-4 py-1 dark:text-gray-200 hover:text-blue-50 text-center text-2xl bg-gradient-to-b from-blue-400 via-blue-300 to-blue-200 rounded-3xl font-roboto">
						<div className="px-4 py-1">
							Request booking
						</div>
					</button>
				</a>
		</div>
	</>
}