export default function Navbar() {
  return (
    <nav className="bg-gradient-to-b from-blue-400 via-blue-300 to-blue-200 shadow dark:bg-gray-800 md:p-5 rounded-b-3xl mx-1 font-roboto">
      <div
        className="container px-6 py-3 mx-auto md:flex md:justify-between md:items-center ">
        <div className="flex items-center justify-between">
          <div>
            <a className="text-2xl sm:text-4xl font-bold text-gray-800 dark:text-white hover:text-blue-50 dark:hover:text-gray-200 " href="/">
              La Maravilla de Ágora
            </a>
          </div>
        </div>

        <div className="items-center sm:flex">
          <div className="flex flex-row text-sm sm:text-xl">
            <a className=" pl-5 pr-3 text-gray-700 dark:text-gray-200 underline hover:text-blue-50 dark:hover:text-blue-300 sm:mx-4 md:my-0 " href="/">
              Home 
            </a>
            <a className=" pr-3 text-gray-700 dark:text-gray-200 underline hover:text-blue-50 dark:hover:text-blue-300 sm:mx-4 md:my-0" href="/Appartement">
              Apartment
            </a>
            <a className=" pr-3 text-gray-700 dark:text-gray-200 underline hover:text-blue-50 dark:hover:text-blue-300 sm:mx-4 md:my-0" href="/Prijzen">
              Prices
            </a>
            <a className=" text-gray-700 dark:text-gray-200 underline hover:text-blue-50 dark:hover:text-blue-300 sm:mx-4 md:my-0" href="/Omgeving">
              Region
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}
