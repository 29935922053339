import Slideshow from './Slideshow_slaapkamer2';

export default function Slaapkamer2() {
	return <>
		<div className="grid grid-cols-10 mt-3">
			<div>
				<a href="/Appartement" className="font-roboto text-xl ml-1 sm:ml-5 py-1 px-3 font-bold border-solid border-2 border-black rounded-lg hover:bg-gray-200">Back</a>
			</div>
			<div className="mb-5 col-span-8 text-center font-lobster text-3xl sm:text-4xl">
				Second Bedroom
				</div>
			<div className="col-span-10 mx-5">
				<Slideshow />
			</div>
			<div className="font-roboto col-span-10 text-l mx-10 my-3">
				<p>
				The bedroom with spacious wardrobe is located on the east side of the accommodation. The curtains ensure sufficient privacy.
You will enjoy a peaceful night's sleep in a king-size bed (180x200cm) in a room that can be completely darkened with shutters. Air conditioning (both cold and hot) and mood lighting complete the picture.
In the morning, you will wake up overlooking the peaceful natural surroundings with a sunrise effect.
Finally, there is the private bathroom for a refreshing shower.

				</p>
			</div>
		</div>
	</>
}