import Slideshow from './Slideshow_home.jsx';


export default function Startscherm() {
	return <>
		<div>
			<div className="m-5 sm:text-sm m-3 text-justify">
				<div>
					<Slideshow />
				</div>
				<div className="mt-5 sm:text-center text-justify font-roboto text-xl">
					<p>La Maravilla de Agora  is a new flat in Agora,  
						a new development of apartments in a unique location 
						in the New Golden Mile, between Marbella and Estepona.  
						All flats in this urbanisation are facing south with 
						unobstructed views over a beautiful natural valley 
						and protected forest area 
						(
							<a className="underline hover:text-blue-400" target="_blank" rel="noreferrer" href="https://www.selwo.es/">
								Selwo
							</a>
						) 
						with sea views from 
						the upper floors, as our apartment is one of these.
						The apartment has a wide open covered terrace and 
						natural stone elements incorporated into the design to 
						blend in with the picturesque natural surroundings.
					</p> 
				</div>
			</div>
		</div>
	</>
}